<script lang="ts" setup>
import { Autoplay as SwiperAutoplay } from "swiper/modules";
import type { EntryCollection } from "contentful";
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeButton } from "~/types/TypeButton";
import nodeRenderers from "~/lib/node-renderers.js";
import type {
  TypeCarouselLogos,
  TypeCompanySkeleton,
  TypeNetworkMemberSkeleton,
} from "~/types/contentful";

const props = defineProps<{
  fields: TypeCarouselLogos<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"] & {
    buttons: TypeButton[];
  };
}>();

const { data: allCompanies } = await useLazyFetch<
  EntryCollection<
    TypeCompanySkeleton | TypeNetworkMemberSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >
>(
  `/api/${props.fields.companyType === "Company" ? "companies" : "network-members"
  }`,
  {
    query: {
      perPage: 999,
    },
    server: false,
  },
);

const companiesSource = computed(() =>
  props.fields.specificCompanies?.length
    ? props.fields.specificCompanies.filter(
      (c): c is NonNullable<typeof c> => !!c,
    )
    : Array.from(allCompanies.value?.items ?? []),
);

const groupedCompanies = computed(() =>
  Array.from(
    Array(Math.ceil((companiesSource.value.length || 0) / 5) || 20).keys(),
  ).map((index) => companiesSource.value.slice(5 * index, 5 * index + 5)),
);
</script>

<template>
  <div class="carousel--logos py-4 md:py-6">
    <Container class="">
      <div
        v-if="fields.displaySectionTitle"
        class="w-full"
      >
        <TextSectionTitle>{{ fields.title }}</TextSectionTitle>
      </div>
    </Container>
    <div>
      <ClientOnly>
        <swiper-container
          :modules="[SwiperAutoplay]"
          :loop="true"
          :speed="10000"
          :free-mode="false"
          :autoplay="{
            delay: 1,
            disableOnInteraction: false,
          }"
          :breakpoints="{
            320: {
              slidesPerView: 4,
              spaceBetween: 12,
            },
            768: {
              slidesPerView: 5,
              spaceBetween: 16,
            },
            1024: {
              slidesPerView: 6,
              spaceBetween: 24,
            },
            1440: {
              slidesPerView: 7,
              spaceBetween: 24,
            },
            1920: {
              slidesPerView: 9,
              spaceBetween: 24,
            },
          }"
        >
          <swiper-slide
            v-for="(column, index) in groupedCompanies"
            :key="`logos${index}`"
            :class="index % 2 ? 'even' : 'odd'"
            class="column h-auto"
          >
            <div
              v-for="company in column"
              :key="company.fields.name"
              class="logo"
            >
              <NuxtImg
                v-if="company.fields.logo?.fields.file"
                :src="company.fields.logo.fields.file.url"
                :alt="company.fields.logo.fields.title"
                :width="300"
                loading="lazy"
                class="rounded-sm border border-ui-grey2"
              />
            </div>
          </swiper-slide>
        </swiper-container>
      </ClientOnly>
    </div>
    <Container>
      <div class="mt-4 flex flex-col items-center justify-center md:flex-row lg:mt-5">
        <NuxtLink
          v-for="button in fields.buttons"
          :key="button.title"
          class="btn mb-3 mr-0 w-auto md:mb-0 md:mr-3"
          :class="'btn--' + button.type"
          :to="button.url"
        >
          {{ button.title }}
        </NuxtLink>
      </div>

      <div
        v-if="fields.disclaimer"
        class="m-auto mt-4 text-center !text-xs !text-dark-blue-grey-two/50 lg:w-2/3"
      >
        <RichTextRenderer
          :document="fields.disclaimer"
          :node-renderers="nodeRenderers"
        />
      </div>
    </Container>
  </div>
</template>

<style lang="scss" scoped>
swiper-container::part(wrapper) {
  transition-timing-function: linear !important;
}

.column.odd {
  .logo:nth-child(odd) {
    @apply my-[12px] px-[24px];
  }
}

.column.even {
  .logo:nth-child(even) {
    @apply my-[12px] px-[24px];
  }
}
</style>
